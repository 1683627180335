import { getEnvVariables } from "env";

const env = getEnvVariables();

const modelV3 = `${env.API_V3_URL}v3/models`;
const sharedModelV3 = `${env.API_V3_URL}v3/shared/models`;

const API = {
  // Auth
  signIn: `${env.API_URL}v2/auth/signIn`,
  signUp: `${env.API_URL}v2/auth/signUp`,
  signOut: `${env.API_URL}v2/auth/signOut`,
  resetPasswordRequest: `${env.API_URL}v2/auth/password`,
  resetPassword: `${env.API_URL}v2/auth/password/:token`,
  // User
  getLoggedUser: `${env.API_V3_URL}v3/users/me`,
  updateUserMetadata: `${env.API_V3_URL}v3/users/me/metadata`,
  deleteAccount: `${env.API_V3_URL}v3/users/me/delete`,
  // Model
  modelIssue: `${modelV3}/:id/issues`,
  publicModelIssue: `${env.API_V3_URL}v3/public/models/:publicKey/issues`,
  getModel: `${modelV3}/:id`,
  getModelThumbnails: `${modelV3}/:id/thumbnails`,
  modelMeasurements: `${modelV3}/:id/measurements`,
  modelDownload: `${modelV3}/:id/downloads`,
  getModels: `${modelV3}`,
  getModelsUploadsVideosUrls: `${modelV3}/videos`,
  emergencyUploadLinkUrl: `${modelV3}/:id/video`,
  getPublicModel: `${env.API_V3_URL}v3/public/models/:publicKey`,
  // Payments
  subscriptionCheckout: `${env.API_V3_URL}v3/payments/checkout/subscriptions/:type/:period`,
  // Organization
  getCurrentOrganization: `${env.API_V3_URL}v3/organizations/me`,
  organizationUsers: `${env.API_V3_URL}v3/organizations/me/users`,
  // Shared models
  getSharedModels: `${sharedModelV3}`,
  getSharedModelsToOthers: `${sharedModelV3}/owned`,
  shareModel: `${env.API_V3_URL}v3/models/:id/shares`,
  getSharedModel: `${sharedModelV3}/:id`,
  // Tags
  tags: `${env.API_V3_URL}v3/tags`,
  // Coupon
  couponValid: `${env.API_URL}v3/coupons/:couponCode`,
  // Reports
  report: `${env.API_URL}v3/reports`,
  // Comment
  getModelComments: `${modelV3}/:id/comments`,
  commentCreate: `${modelV3}/:id/comments`,
  deleteComment: `${modelV3}/:modelId/comments/:commentId`,
  // Stream
  getStreams: `${env.API_V3_URL}v3/streams`,
};

export default API;
